import React from "react"
import classes from './PasscodeContentLayout.module.css'
import { type } from "@testing-library/user-event/dist/type"
import keyIcon from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/key.png'
import cloudIcon from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/cloud.png'

const PasscodeContentLayout = (passcodeData) => {

    const iconMapping = [
        keyIcon, 
        cloudIcon
    ]

    const createdFor = passcodeData.passcodeData.generatedFor
    const createdBy = passcodeData.passcodeData.generatedBy
    const passcodeType = passcodeData.passcodeData.type
    const startDate = passcodeData.passcodeData.startDate
    const startDateToFormat = new Date(startDate)
    const formattedStartDate = startDateToFormat.toLocaleString("en-GB", {
        weekday: "long", 
        day: "numeric", 
        month: "long", 
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    const endDate = passcodeData.passcodeData.endDate
    const endDateToFormat = new Date(endDate)
    const formattedEndDate = endDateToFormat.toLocaleString("en-GB", {
        weekday: "long", 
        day: "numeric", 
        month: "long", 
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      });
    const passcode = passcodeData.passcodeData.pincode
    const isManualPin = passcodeData.passcodeData.isManualPin
    let iconPath;
    let formattedType;
    switch (isManualPin){
        case 0:
            iconPath = iconMapping[1]
            switch (passcodeType){
                case 1:
                    //ONETIME
                    formattedType = "autoPIN [Once]"
                    break;
                case 2:
                    //PERMANANT
                    formattedType = "autoPIN [Permanent]"
                    break;
                case 3:
                    //DURATION
                    formattedType = "autoPIN [Duration]"
                    break;
            }
            break;
        case 1:
            iconPath = iconMapping[0]
            switch (passcodeType){
                case 0:
                    //ONETIME
                    formattedType = "customPIN [Once]"
                    break;
                case 1:
                    //PERMANANT
                    formattedType = "customPIN [Permament]"
                    break;
                case 2:
                    //WEEKLY
                    formattedType = "customPIN [Weekly]"
                    break;
                case 3:
                    //DURATION
                    formattedType = "customPIN [Duration]"
                    break;
            }
            break;
    }
    

    return (
        <div className={classes.container} >
            <h1 className={classes.title}>{passcode}</h1>
            <h3 className={classes.title}>Created for: {createdFor}</h3>
            <h3 className={classes.subtitle}>Created by: {createdBy}</h3>
            <h3 className={classes.subtitle}>
            <img 
                src={iconPath} 
                alt="Lock Icon" 
                style={{ width: "18px", height: "18px", marginRight: "5px", verticalAlign: "middle" }} 
            />
                {formattedType}
            </h3>
            <h3 className={classes.subtitle}>Start: {formattedStartDate}</h3>
            {passcodeType == 3 || passcodeType == 2 && (<h3 className={classes.subtitle}>End: {formattedEndDate}</h3>)}
        </div>
    )
}

export default PasscodeContentLayout;