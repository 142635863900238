import React, { Children, useEffect, useState, useRef } from 'react'
import classes from "./LockContentLayout.module.css";
import arrowIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Site/__Page - Forward.svg";
import Modal from '../../../../../Modal/Modal';
import { useDispatch, useSelector } from 'react-redux';
import MasterPinIcon from '../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/misc/visibility.png'
import OTPIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/Pin Icons/__Access.svg";
import RemoveLockIcon from "../../../../../../assets/Portal Iconnography SVGs/PortalAppRealted _Icons/DropDown Icons/__Delete - Remove.svg"
import GenerateLockOnTimePassCodeForm from '../../EditAutomations/GenerateLockOnTimePassCodeForm';
import { GenerateLockOTP, GenerateLockOTPAPI, getPadlockPincodes, removeLockAPI } from '../../../../../../Api Methods/Api';
import Button1 from '../../../../Button/Button1';
import ShowOTPPopup from './ShowOTPPopup';
import { Await } from 'react-router-dom';
import PasscodeContentLayout from './PasscodeContentLayout';
import Button from '../../../../Button/Button';

const LockContentLayout = ({ outputPinName, iglooLocks, setRefreshVirtualSources }) => {

    const { lockID, adminKey, battery, batteryReadAt, masterPin, outputPin, type } = iglooLocks;
    const PinName = outputPinName[0].pinName;
    const OutputPin = outputPinName[0].Pin;
    const client = useSelector(state => state.auth.client);
    const token = useSelector(state => state.auth.token);
    const DeviceUUID = useSelector(state => state.auth.DeviceUUID);
    const [passcodes, setPasscodes] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const apiData = {
        token,
        client
    }
    const ModalDialog = useRef();
    const ClearStatesChildComponetRef = useRef();

    const HandleShowModal = () => {
        ModalDialog.current.open();
    }

    const HandleCloseModal = () => {
        ModalDialog.current.close();
        if (ClearStatesChildComponetRef.current && ClearStatesChildComponetRef.current.HandleResetAllStates) {
            ClearStatesChildComponetRef.current.HandleResetAllStates();
        }

        HandelResetModal();
    }

    const [modalTitle, setModalTitle] = useState("Generate Access")
    const [modalComponent, setModalComponent] = useState()
    const [modalPageIndex, setModalPageIndex] = useState(null);
    const [OTPCode, setOTPCode] = useState(null);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                HandelResetModal();
                setModalPageIndex(null);
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const HandelResetModal = () => {
        setModalPageIndex(null);
    }

    useEffect(() => {
        switch (modalPageIndex) {
            case 1:
                setModalComponent(<GenerateLockOnTimePassCodeForm GenerateOTP={GenerateOTP} />)
                HandleShowModal();
                break;
            case 2:
                setModalTitle("Lock Access Code")
                setModalComponent(<ShowOTPPopup pincode={OTPCode} closeModal={HandleCloseModal}/>)
                break;
        }

    }, [modalPageIndex])

    const dispatch = useDispatch();
    const GenerateOTP = async (otpName, startDate, endDate, type) => {
        const body = {
            lockID: lockID,
            startDate: startDate,
            endDate: endDate,
            type: type,
            uuid: DeviceUUID,
            otpName: otpName,
        }

        const responseData = await GenerateLockOTPAPI(apiData, body, dispatch)
        if (responseData && responseData.success) {
            setOTPCode(responseData.data.pin);
            setModalPageIndex(2)
            setRefreshVirtualSources(true);
        } else {
            setOTPCode(responseData.msg);
            setModalPageIndex(2)
        }
    }

    // const removeLock = async () => {
    //     const responseData = await removeLockAPI(apiData, DeviceUUID, OutputPin, dispatch)

    //     if (responseData.success) {
    //         setRefreshVirtualSources(true);
    //         HandleCloseModal();
    //     }
    // }

    const HandleShowMasterPin = (masterPin) => {
        HandleCloseModal()
        setModalTitle("Master pin")
        setModalComponent(
            <div>
            <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "fit-content",
                    }}>
                <h1>{masterPin}</h1>
                <br></br>
                
            </div>
            <div style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    height: "fit-content",
                    }}>
                <Button1 onClick={HandleCloseModal}>Close</Button1>
            </div>
            </div>
        )
        HandleShowModal();

    }


    const HandleGetPincodes = async () => {
        setModalTitle("Passcodes");
        const loadedPasscodes = await getPadlockPincodes(apiData, lockID, dispatch) // Wait for passcodes to load
        setPasscodes(loadedPasscodes.data); 
        //setIsLoading(false)
        HandelResetModal()
        setModalComponent(
            // <div>
            //     { isLoading ? (
            //     <div>
            //         <h3>Loading...</h3>
            //     </div>
            // ) : (
                <div style={{
                    maxHeight: "80vh", // Prevents modal from getting too tall
                    overflowY: "auto", // Enables scrolling for overflowing content
                    padding: "20px"
                }}>
                    <div style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        marginBottom: "10px"
                    }}>
                        <Button1 onClick={() => HandleShowMasterPin(masterPin)}>Reveal master pin</Button1>
                    </div>
            
            
                    <div style={{
                        maxHeight: "70vh", // Restricts the height of the passcode list
                        overflowY: "auto", // Enables scrolling when necessary
                        padding: "10px"}}>

                        {loadedPasscodes.data.length === 0 ? (
                            <div style={{ textAlign: "center", padding: "20px" }}>
                                <h3>No passcodes created</h3>
                            </div>
                        ) : (
                            loadedPasscodes.data.map((passcode, index) => (
                                <div key={index}>
                                    <PasscodeContentLayout passcodeData={passcode} />
                                </div>
                            ))
                        )}
                    </div>
                </div>
            // )}
            // </div>
        );


        HandleShowModal()
    }

    return (
        <>
            <Modal ref={ModalDialog} title={modalTitle} HandleShowModal={HandleShowModal} HandleCloseModal={HandleCloseModal} style={{ minWidth: "600px", minHeight: modalPageIndex === 1 ? "665px" : "250px"}} modalLayoutStyle={{ alignItems: "center" }}>
                {modalComponent}
            </Modal>

            <div className={classes.LockContentLayout} >
                <div className={classes["left-wrapper"]}>
                    <h3 className={classes.title}>{"Smart Lock"}</h3>
                    <h3 className={classes.subtitle}>{lockID}</h3>
                    {OTPCode && "OTP: " + OTPCode}
                </div>

                <div className={classes["right-wrapper"]}>
                    <h3 className={classes.pinName}>{PinName}</h3>

                    <div className={classes["Icons-Container"]} >
                        <img className={classes.Icon} src={OTPIcon} onClick={() => setModalPageIndex(1)} />
                        <img className={classes.RemoveLockIcon} src={MasterPinIcon} onClick={HandleGetPincodes} />
                    </div>
                </div>
            </div >
        </>
    )
}

export default LockContentLayout;




