import React, { useRef, useEffect, useState, Fragment, forwardRef } from 'react'
import DropdownItem from './DropdownItem';
import classes from './DropdownMenu.module.css';

const DropdownList = forwardRef((props, ref) => {
    const buttonRef = props.buttonRef;
    const dropdownRef = props.dropdownRef;

    return (
        <div className={classes.dropDownButton} >
            <img
                className={classes.img}
                src={props.icon}
                onClick={() => props.onClick(props.item)}
                placeholder="Settings"
                ref={buttonRef}
            />
            {props.ifSettingsCogIsClicked && <div className={classes["dropDownlist_container"]} ref={ref}>
                {/* <h3>{props.title}</h3> */}
                <div className={classes["list-container"]}>
                    <ul >
                        {props.listItems.map((item, index) => (
                            item.divider ? (
                                <hr key={index} style={{ margin: "8px 0", border: "1px solid #ccc" }} />
                              ) : (
                                <li key={index}>
                                    <DropdownItem
                                        ref={dropdownRef}
                                        item={item}
                                        className={classes["DropdownItem-container"]}
                                        img={classes["img"]}
                                        onClick={(userAction) =>
                                            props.onUserActionClick(userAction)
                                        } />
                                </li>
                            )))
                        }
                    </ul>
                </div>
            </div>}
        </div>
    )
})

export default DropdownList;